/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiStarLine } from "react-icons/ri"

const EmployeeEngagementSurveyDownloadPage = () => {
  
  // List of engagement survey questions by category
  const surveyQuestions = {
    workEnvironment: [
      "I have the resources and tools I need to do my job effectively",
      "My physical workspace is comfortable and supports my wellbeing",
      "I feel safe expressing my opinions at work",
      "Our company culture values work-life balance",
      "I feel connected to my colleagues even when working remotely"
    ],
    roleClarity: [
      "I clearly understand what is expected of me in my role",
      "I understand how my work contributes to company goals",
      "I receive regular feedback on my performance",
      "My role provides opportunities to use my strengths",
      "I have clarity on my career path within the organization"
    ],
    management: [
      "My manager provides clear direction and purpose",
      "I receive recognition when I do good work",
      "My manager cares about me as a person, not just an employee",
      "My manager removes obstacles that prevent me from doing my best work",
      "I have regular one-on-one meetings with my manager"
    ],
    growth: [
      "I have access to learning and development opportunities",
      "There are opportunities for career advancement at our company",
      "I feel challenged and growing in my current role",
      "I receive support to develop new skills",
      "The company invests in professional development"
    ],
    alignment: [
      "I believe in our company's mission and purpose",
      "I understand and support our company's strategic goals",
      "Our company's values align with my personal values",
      "I would recommend our company as a great place to work",
      "I see myself working here in two years"
    ]
  }
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Employee Engagement Survey Template | Printable PDF Assessment Tool"
        description="Free, research-backed employee engagement survey template with 25 validated questions across 5 key engagement areas. Download or print this PDF template for immediate use."
        keywords={[
          "employee engagement survey",
          "engagement assessment template",
          "workplace satisfaction questionnaire",
          "staff pulse survey",
          "printable engagement form",
          "employee feedback tool",
          "workforce engagement metrics",
          "HR survey template",
          "employee sentiment analysis",
          "workplace culture assessment"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-engagement" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Engagement Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Employee Engagement Survey Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive, research-backed survey template to accurately measure employee engagement 
              across five key dimensions. Use this validated assessment tool to identify strengths 
              and opportunities for improving workplace engagement.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <a 
                href="/templates/employee-engagement-survey.pdf" 
                download
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Download PDF
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Template Preview Section */}
      <div sx={{
        bg: 'white',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1000px',
          mx: 'auto',
          px: 3,
        }}>
          <div className="printable-template" sx={{
            border: '1px solid',
            borderColor: 'rgba(0,0,0,0.1)',
            borderRadius: '8px',
            p: [3, 4],
            mb: 5,
            boxShadow: '0 2px 15px rgba(0,0,0,0.05)',
            '@media print': {
              border: 'none',
              boxShadow: 'none',
              p: 0
            }
          }}>
            {/* Template Header */}
            <div sx={{
              borderBottom: '2px solid',
              borderColor: 'primary',
              pb: 3,
              mb: 4,
              '@media print': {
                pb: 2,
                mb: 3
              }
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.8rem'],
                fontWeight: 700,
                color: 'primary',
                textAlign: 'center',
                mb: 2
              }}>
                Employee Engagement Survey
              </h2>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                textAlign: 'center',
                mb: 3
              }}>
                Your feedback is important to us. Please rate each statement according to how strongly you agree or disagree.
                All responses are confidential and will be used to improve our workplace experience.
              </p>
              
              <div sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)',
                pt: 2,
                fontSize: '0.8rem',
                color: 'text',
                '@media print': {
                  pt: 1
                }
              }}>
                <div>
                  <strong>Department/Team:</strong> ____________________
                </div>
                <div>
                  <strong>Date:</strong> ____________________
                </div>
                <div>
                  <strong>Tenure:</strong> □ &lt;1yr □ 1-3yrs □ 3-5yrs □ 5+ yrs
                </div>
              </div>
            </div>
            
            {/* Rating Scale Legend */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 4,
              fontSize: '0.8rem',
              '@media print': {
                mb: 3
              }
            }}>
              <div sx={{ textAlign: 'center', width: '20%' }}>
                <div sx={{ 
                  bg: 'rgba(0,0,0,0.05)', 
                  borderRadius: '4px', 
                  p: 1, 
                  mb: 1,
                  fontWeight: 600 
                }}>
                  1
                </div>
                <div>Strongly Disagree</div>
              </div>
              <div sx={{ textAlign: 'center', width: '20%' }}>
                <div sx={{ 
                  bg: 'rgba(0,0,0,0.05)', 
                  borderRadius: '4px', 
                  p: 1, 
                  mb: 1,
                  fontWeight: 600 
                }}>
                  2
                </div>
                <div>Disagree</div>
              </div>
              <div sx={{ textAlign: 'center', width: '20%' }}>
                <div sx={{ 
                  bg: 'rgba(0,0,0,0.05)', 
                  borderRadius: '4px', 
                  p: 1, 
                  mb: 1,
                  fontWeight: 600 
                }}>
                  3
                </div>
                <div>Neutral</div>
              </div>
              <div sx={{ textAlign: 'center', width: '20%' }}>
                <div sx={{ 
                  bg: 'rgba(0,0,0,0.05)', 
                  borderRadius: '4px', 
                  p: 1, 
                  mb: 1,
                  fontWeight: 600 
                }}>
                  4
                </div>
                <div>Agree</div>
              </div>
              <div sx={{ textAlign: 'center', width: '20%' }}>
                <div sx={{ 
                  bg: 'rgba(0,0,0,0.05)', 
                  borderRadius: '4px', 
                  p: 1, 
                  mb: 1,
                  fontWeight: 600 
                }}>
                  5
                </div>
                <div>Strongly Agree</div>
              </div>
            </div>
            
            {/* Survey Questions */}
            {Object.entries(surveyQuestions).map(([category, questions], categoryIndex) => (
              <div 
                key={category}
                sx={{
                  mb: 4,
                  '@media print': {
                    mb: 3,
                    pageBreakInside: 'avoid'
                  }
                }}
              >
                <h3 sx={{
                  fontSize: ['1.1rem', '1.2rem'],
                  fontWeight: 600,
                  color: 'primary',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <RiStarLine /> {(() => {
                    switch(category) {
                      case 'workEnvironment': return 'Work Environment & Culture';
                      case 'roleClarity': return 'Role Clarity & Purpose';
                      case 'management': return 'Management & Leadership';
                      case 'growth': return 'Growth & Development';
                      case 'alignment': return 'Organizational Alignment';
                      default: return category;
                    }
                  })()}
                </h3>
                
                <div sx={{
                  bg: categoryIndex % 2 === 0 ? 'rgba(0,0,0,0.02)' : 'transparent',
                  p: 2,
                  borderRadius: '6px'
                }}>
                  {questions.map((question, index) => {
                    const questionId = `${category}-q${index}`;
                    return (
                      <div 
                        key={questionId}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          py: 2,
                          borderBottom: index < questions.length - 1 ? '1px solid' : 'none',
                          borderColor: 'rgba(0,0,0,0.1)',
                          '@media print': {
                            py: 1
                          }
                        }}
                      >
                        <div sx={{ 
                          flex: '1', 
                          mr: 3, 
                          fontSize: ['0.9rem', '0.95rem'],
                          '@media print': {
                            fontSize: '0.85rem'  
                          }
                        }}>
                          <label htmlFor={questionId}>{question}</label>
                        </div>
                        <div sx={{
                          display: 'flex',
                          gap: [1, 2],
                          flexShrink: 0,
                          minWidth: '150px',
                          justifyContent: 'space-between'
                        }}>
                          {[1, 2, 3, 4, 5].map(rating => (
                            <div 
                              key={`${questionId}-r${rating}`}
                              id={rating === 1 ? questionId : undefined}
                              sx={{
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                border: '1px solid',
                                borderColor: 'rgba(0,0,0,0.3)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '0.7rem',
                                '@media print': {
                                  width: '20px',
                                  height: '20px',
                                  fontSize: '0.65rem'
                                }
                              }}
                            >
                              {rating}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
            
            {/* Open-ended Questions */}
            <div sx={{
              mt: 4,
              mb: 3,
              '@media print': {
                mt: 3,
                mb: 2,
                pageBreakInside: 'avoid'
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 2
              }}>
                Additional Feedback
              </h3>
              
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}>
                <div>
                  <label 
                    htmlFor="feedback-like-most"
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.95rem', 
                      fontWeight: 600,
                      mb: 1
                    }}
                  >
                    What do you like most about working here?
                  </label>
                  <div 
                    id="feedback-like-most"
                    sx={{
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px',
                      height: '70px',
                      mt: 1
                    }} 
                  />
                </div>
                
                <div>
                  <label 
                    htmlFor="feedback-improvements" 
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.95rem', 
                      fontWeight: 600,
                      mb: 1
                    }}
                  >
                    What changes would most improve your engagement at work?
                  </label>
                  <div 
                    id="feedback-improvements"
                    sx={{
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px',
                      height: '70px',
                      mt: 1
                    }} 
                  />
                </div>
                
                <div>
                  <label 
                    htmlFor="feedback-additional"
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.95rem', 
                      fontWeight: 600,
                      mb: 1
                    }}
                  >
                    Any additional comments or suggestions?
                  </label>
                  <div 
                    id="feedback-additional"
                    sx={{
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px',
                      height: '70px',
                      mt: 1
                    }} 
                  />
                </div>
              </div>
            </div>
            
            {/* Footer */}
            <div sx={{
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)',
              textAlign: 'center',
              fontSize: '0.8rem',
              color: 'text',
              opacity: 0.7,
              '@media print': {
                mt: 3,
                pt: 2
              }
            }}>
              Thank you for your feedback! Your insights help us create a better workplace.
            </div>
          </div>
          
          <div sx={{
            display: ['block', 'flex'],
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media print': {
              display: 'none'
            }
          }}>
            <div sx={{
              mb: [3, 0]
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                color: 'text',
                mb: 1
              }}>
                How to use this template
              </h3>
              
              <ul sx={{
                pl: 3,
                fontSize: '0.95rem'
              }}>
                <li sx={{ mb: 1 }}>Print and distribute to employees or create a digital version</li>
                <li sx={{ mb: 1 }}>Ensure anonymity to encourage honest feedback</li>
                <li sx={{ mb: 1 }}>Analyze results to identify engagement trends</li>
                <li sx={{ mb: 1 }}>Develop action plans based on feedback</li>
                <li sx={{ mb: 1 }}>Conduct the survey quarterly or bi-annually</li>
              </ul>
            </div>
            
            <div sx={{
              textAlign: ['left', 'right']
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease',
                  mb: 2,
                  '&:hover': {
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <p sx={{
                fontSize: '0.8rem',
                color: 'text',
                opacity: 0.7
              }}>
                For more HR templates and tools, <Link to="/guides/employee-engagement" sx={{ color: 'primary' }}>visit our guide</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmployeeEngagementSurveyDownloadPage 